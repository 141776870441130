
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/Mobile/CustomSlider.vue";
import { kMobileMenusConfig } from "@/config/Mobile/MenusConfig";
import BrandCulture from "./BrandCulture.vue";
import CompanyCulture from "./CompanyCulture.vue";
import NaturalFarming from "./NaturalFarming.vue";
import PublicWelfare from "./PublicWelfare.vue";
// import Claim from "../News/index.vue";
import PageFooter from "@/components/Mobile/PageFooter.vue";

@Options({
  components: {
    CustomSlider,
    BrandCulture,
    NaturalFarming,
    CompanyCulture,
    PublicWelfare,
    // Claim,
    PageFooter,
  },
  data() {
    return {
      selectedIndex: 0,
      items: kMobileMenusConfig.zz,
    };
  },
  methods: {
    handleSwitchTab(index: number) {
      this.selectedIndex = index;
    },
  },
})
export default class Page extends Vue {}
