import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Summary = _resolveComponent("Summary")!
  const _component_Recommend = _resolveComponent("Recommend")!
  const _component_RelatedPeople = _resolveComponent("RelatedPeople")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Summary),
    _createVNode(_component_Recommend),
    _createVNode(_component_RelatedPeople)
  ], 64))
}