
import { Options, Vue } from "vue-class-component";
import Summary from "@/views/Common/SubDiv/NaturalFarming/Summary.vue";
import Recommend from "@/views/Common/SubDiv/NaturalFarming/Recommend.vue";
import RelatedPeople from "@/views/Common/SubDiv/NaturalFarming/RelatedPeople.vue";
require("@/less/mobile/natural_farming_style.less");

@Options({
  components: {
    Summary,
    Recommend,
    RelatedPeople,
  },
  data() {
    return {};
  },
})
export default class NaturalFarming extends Vue {}
