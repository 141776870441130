
import { Options, Vue } from "vue-class-component";
import { kPublicWelfarePageContent } from "@/config/Common/public_welfare_data";
import BoxWithNews from "@/components/Mobile/BoxWithNews.vue";

@Options({
  components: {
    BoxWithNews,
  },
  data() {
    return {
      info: kPublicWelfarePageContent,
    };
  },
})
export default class PublicWelfare extends Vue {}
