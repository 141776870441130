export const kNaturalFarmingPageContent = {
  pageName: "自然农法",
  summary: {
    title: "自然农法简介",
    content: `发源于中国道家思想，并与现代量子思维高度吻合。在农业上实践"无"的哲学，"自然农法"提倡：不耕地、不施肥、不打药、不除草，运用"互生、共存、共荣"的理念，最大限度尊重自然规律，减少人为干预，保持农作生态有序循环。帮助人们获得更有力量的食物，改善土地荒漠，保护生态环境。`,
  },
  recommend: {
    title: "推荐",
    list: [
      {
        theme: "电影推荐",
        img: require("@/assets/framing/r_movie.png"),
        title: "奇迹的苹果",
        des: [
          `《奇迹的苹果》是由东宝国际发行的剧情片，该片由中村义洋执导，阿部隆史、菅野美穗、池内博之等主演。`,
          `该片根据真实事迹改编，讲述了主人公木村秋则以妻子健康状况不佳为契机，
                    坚持对苹果进行无农药栽培，辛勤劳作11年终于培育出纯天然苹果的感人故事。该片于2013年6月8日在日本上映。`,
        ],
      },
      {
        theme: "书籍推荐",
        img: require("@/assets/framing/r_book.png"),
        title: "一颗稻草的使命",
        des: [
          `1975年，福冈正信出版的《一根稻草的革命》，获得了许多世界级大奖，成为生态农人们的思想导航。`,
          `一场孰其来的疾病让年触福冈正信重新思考人生，为何小鸟能在空中自由飞翔，而人类却要每日为衣食奔波？为何原本自然生长的稻田，
                    在会加了农药和机械的助力后，地力反而衰竭了？是否人类也在走向迷途？福冈正信用数十年的时间证明"不耕作、不施肥、不除草、
                    不用农药"才是理想的稻作，他也因此寻找到了尊重生命，与地球友善相处的自然农法。`,
        ],
      },
    ]
  },
  relatedPeople: {
    title: "相关人物",
    list: [
      {
        name: "福冈正信",
        img: "r_figure_01.png",
        children: [
          {
            des: "他说：我做农民公民，就是为了确认和证实人类并非全知。我不反对科学，只是反对傲慢的科学。",
          },
        ],
      },
      {
        name: "木村秋则",
        img: "r_figure_02.png",
        children: [
          {
            des: `木村秋则放弃那些让他妻子严重过敏的农药，以有机的方式种植苹果。可是这一实验就是十年，直到第十一年，
                  木村阿公的苹果园才开出了花朵。有人把木村阿公的故事写成了书，有人给他拍成了电影，木村却依然是那个亲自种地、
                  到处教授堆肥技术的老人。`,
          },
        ],
      },
      {
        name: "藤田和芳",
        img: "r_figure_03.png",
        children: [
          {
            des: `1975年，藤田和芳创办守护大地协会，由消费者和生产者联合发起，成为日本社会推行有机农业最早的机构之一。
                  时至今天，现在的守护大地协会已发展成为拥有2500个生产会员、9.1万个消费会员、年营业额达150多亿日元的庞大组织。`,
          },
        ],
      },
      {
        name: "池田秀夫",
        img: "r_figure_04.png",
        children: [
          {
            des: `出生于1935年的日本福冈县，年过花甲之后开始来到异国他乡，从此长年跋涉在中国的乡间田野，穿行于泥土之间。
                  从2002年开始，池田秀夫四处讲学，教导健康的土壤对生态有机农业的重要性，培训中国新农人如何堆肥，减少直至放弃化肥的使用。`,
          },
        ],
      },
      {
        name: "川崎广人",
        img: "r_figure_05.png",
        children: [
          {
            des: `在河南原阳县的小刘固农村，数年如一日地坚持着改良农场土壤，推广循环农业，培训过数干名的新农人。`,
          },
        ],
      },
      {
        name: "金子美登夫妇",
        img: "r_figure_06.png",
        children: [
          {
            des: `身为有机领域的先驱实践者，霜里有机农场夫妇在日本有机农业领域耕耘了半个世纪，获得第六届“一个地球奖”获得终身成就奖。`,
          },
        ],
      },
    ],
  }
}