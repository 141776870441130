
import { Options, Vue } from "vue-class-component";
import ThreeDesire from "@/views/Common/SubDiv/BrandCulture/ThreeDesire.vue"
import TenValue from "@/views/Common/SubDiv/BrandCulture/TenValue.vue"

require("@/less/mobile/brand_culture_desire_style.less");
require("@/less/mobile/brand_culture_value_style.less");

@Options({
  components: {
    ThreeDesire,
    TenValue,
  },
  data() {
    return {};
  },
})
export default class BrandCulture extends Vue {}
