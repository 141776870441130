import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "natural_farming_related_people_content" }
const _hoisted_2 = { class: "natural_farming_related_people_title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "natural_farming_related_people_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($data.info.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.info.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "natural_farming_related_people_box",
        key: index
      }, [
        _createElementVNode("img", {
          class: "natural_farming_related_people_img",
          src: require(`@/assets/framing/${item.img}`)
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, _toDisplayString(`${item.name} :`), 1),
        _createVNode(_component_Box, {
          items: item.children,
          des_bold: true
        }, null, 8, ["items"])
      ]))
    }), 128))
  ]))
}