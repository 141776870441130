
import { Options, Vue } from "vue-class-component";
import VideoDiv from "@/views/Common/SubDiv/CompanyCulture/VideoDiv.vue";
import AudioDiv from "@/views/Common/SubDiv/CompanyCulture/AudioDiv.vue";
import BookDiv from "@/views/Common/SubDiv/CompanyCulture/BookDiv.vue";
import Painter from "@/views/Common/SubDiv/CompanyCulture/Painter.vue";

import { kCompanyCulturePageContent } from "@/config/Common/company_culture_data";
import { isMobileDevice } from "@/utils/utils";

if (isMobileDevice()) {
  require("@/less/mobile/company_culture_style.less");
}

@Options({
  components: {
    VideoDiv,
    AudioDiv,
    BookDiv,
    Painter,
  },
  data() {
    return {
      info: kCompanyCulturePageContent,
    };
  },
})
export default class CompanyCulture extends Vue {}
