import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "company_culture_painter_div" }
const _hoisted_2 = { class: "company_culture_painter_div_title" }
const _hoisted_3 = { class: "company_culture_painter_div_boxs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxWithNews = _resolveComponent("BoxWithNews")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(this.$props.item.title), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.item.details, (item, index) => {
          return (_openBlock(), _createBlock(_component_BoxWithNews, {
            key: index,
            item: item
          }, null, 8, ["item"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_PageFooter)
  ], 64))
}