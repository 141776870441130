import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "natural_farming_summary_content" }
const _hoisted_2 = { class: "natural_farming_summary_title" }
const _hoisted_3 = { class: "natural_farming_summary_des" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($data.info.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString($data.info.content), 1)
  ]))
}