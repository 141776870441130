
import { kNaturalFarmingPageContent } from "@/config/Common/natural_farming_data";

export default {
  name: "Recommend",
  data() {
    return {
      info: kNaturalFarmingPageContent.recommend,
    };
  },
};
