
import { kNaturalFarmingPageContent } from "@/config/Common/natural_farming_data";
import Box from "@/components/Mobile/Box.vue";

export default {
  name: "RelatedPeople",
  components: {
    Box,
  },
  data() {
    return {
      info: kNaturalFarmingPageContent.relatedPeople,
    };
  },
};
