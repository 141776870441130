
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    des_bold: Boolean,
    items: Array,
  },
})
export default class CustomSlider extends Vue {}
