
import { Options, Vue } from "vue-class-component";
import BoxWithNews from "@/components/Mobile/BoxWithNews.vue";

@Options({
  components: {
    BoxWithNews,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      page: 0,
    };
  },
})
export default class Painter extends Vue {}
