import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "page_withFooter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandCulture = _resolveComponent("BrandCulture")!
  const _component_NaturalFarming = _resolveComponent("NaturalFarming")!
  const _component_PublicWelfare = _resolveComponent("PublicWelfare")!
  const _component_CompanyCulture = _resolveComponent("CompanyCulture")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.selectedIndex == 0)
        ? (_openBlock(), _createBlock(_component_BrandCulture, { key: 0 }))
        : (_ctx.selectedIndex == 1)
          ? (_openBlock(), _createBlock(_component_NaturalFarming, { key: 1 }))
          : (_ctx.selectedIndex == 2)
            ? (_openBlock(), _createBlock(_component_PublicWelfare, { key: 2 }))
            : (_ctx.selectedIndex == 3)
              ? (_openBlock(), _createBlock(_component_CompanyCulture, { key: 3 }))
              : _createCommentVNode("", true),
      _createVNode(_component_PageFooter)
    ]),
    _createVNode(_component_CustomSlider, {
      items: _ctx.items,
      onSwitchTab: _ctx.handleSwitchTab
    }, null, 8, ["items", "onSwitchTab"])
  ], 64))
}