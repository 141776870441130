
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {
      data: this.item,
      showBg: true,
    };
  },
  methods: {
    doPlayVideo() {
      this.showBg = false;
    },
  },
})
export default class VideoDiv extends Vue {}
