
import { kNaturalFarmingPageContent } from "@/config/Common/natural_farming_data";

export default {
  name: "Summary",
  data() {
    return {
      info: kNaturalFarmingPageContent.summary,
    };
  },
};
